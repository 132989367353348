
import Vue from 'vue';
import { DataTableHeader } from 'vuetify';

import { Client } from '@/shared/types';
import { Models, Services } from '@/injectables/tokens';
import { RouterModelContract, TaxonomyServiceContract, ClientServiceContract } from '@/injectables';

import ClientInfoDialog from '@/components/Clients/ClientInfoDialog.vue';
import WrapperWithTooltip from '@/components/WrapperWithTooltip.vue';
import AppBar from '@/widgets/clients/clients-app-bar.vue';
import { debounce } from 'lodash';

export default Vue.extend({
  name: 'Clients',

  inject: ['showSnackbar'],

  useInjectable: [Services.Client, Models.Router],

  components: {
    ClientInfoDialog,
    WrapperWithTooltip,
    AppBar,
  },

  props: {
    onlyMine: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    filters: {
      handler() {
        this.setFirstPage();
        this.getClients();
      },
      deep: true,
    },
    searchTerm() {
      this.setFirstPage();
      this.getClients();
    },
  },

  data: (): {
    selectedClientId: string | null;
    showClientDialog: boolean;
    loading: boolean;
    page: number;
    clients: Client[];
    total: number;
    mine: boolean;
  } => ({
    selectedClientId: null,
    showClientDialog: false,
    clients: [],
    page: 1,
    total: 0,
    loading: false,
    mine: false,
  }),

  beforeMount() {
    const routerEntity = this.$container.get(Models.Router);
    const queryKeys = Object.keys(this.$route.query);

    if (!queryKeys.includes('itemsPerPage')) this.itemsPerPage = 15;

    this.mine = this.$route.query.mine === 'true';

    if (queryKeys.length !== 0 && !(queryKeys.length === 1 && queryKeys.includes('itemsPerPage'))) return;

    const sorting = (routerEntity as RouterModelContract).getDefaultClientSorting();
    this.updateRoute({ sorting });
  },

  mounted() {
    this.$store.dispatch('client/getCategories');
    this.getClients();
  },

  computed: {
    filters() {
      const {
        category,
        agency,
        subAgency,
        modifiedFrom,
        modifiedTo,
      }: { category: string; agency: string; subAgency: string; modifiedFrom: string; modifiedTo: string } =
        this.$route.query;
      const routerEntity = this.$container.get(Models.Router);

      return (routerEntity as RouterModelContract).packClientFilters(category, agency, subAgency, {
        modified: [modifiedFrom, modifiedTo],
      });
    },
    searchTerm() {
      return this.$route.query.searchKey;
    },
    headers(): DataTableHeader<never>[] {
      const isAdmin = this.$store.getters['auth/isAdmin'];
      const clientColumnWidth = isAdmin ? '300px' : '325px';
      const businessCategoryColumnWidth = isAdmin ? '300px' : '325px';

      const headers: DataTableHeader<never>[] = [
        { text: '', width: '50px', align: 'center', value: 'logo', sortable: false, filterable: false },
        { text: 'Client', width: clientColumnWidth, align: 'start', value: 'name', filterable: true },
        { text: 'Business Category', value: 'category', width: businessCategoryColumnWidth, filterable: false },
        { text: 'Modified', width: '150px', value: 'lastUpdated', filterable: false },
      ];
      if (isAdmin) {
        headers.push({ text: 'Agency', width: '150px', value: 'AgencyPartner', filterable: false });
      }
      return headers;
    },
    createOrEdit(): boolean {
      return this.$route.name === 'createClient' || this.$route.name === 'editClient';
    },
    sorting() {
      const routerEntity = this.$container.get(Models.Router);
      const sorting = (routerEntity as RouterModelContract).getDefaultClientSorting();
      const { sortBy, desc: sortDesc } = this.$route.query;

      const options = { ...sorting, sortBy, sortDesc };

      return {
        sortBy: options.sortBy,
        sortDesc: (routerEntity as RouterModelContract).stringToBoolean(options.sortDesc),
      };
    },
    itemsPerPage: {
      get(): number {
        const routerEntity = this.$container.get(Models.Router);
        return (routerEntity as RouterModelContract).getValidItemPerPage(this.$route.query.itemsPerPage);
      },
      set(itemsPerPage: number) {
        this.updateRoute({ itemsPerPage });
      },
    },
    skippedRows() {
      return (this.page - 1) * this.itemsPerPage;
    },
    isAdmin() {
      return this.$store.getters['auth/isAdmin'];
    },
  },

  methods: {
    async openClientInfoDialog(id: string): Promise<void> {
      await this.$store.dispatch('client/updateActiveClientId', id);
      this.showClientDialog = true;
      this.selectedClientId = id;
    },
    closeClientInfoDialog() {
      this.showClientDialog = false;
      this.selectedClientId = null;
    },
    friendlyCategory(category: string): string {
      if (!category) return '';
      const taxonomyService: TaxonomyServiceContract = this.$container.get(Services.Taxonomy);
      return taxonomyService.fullTitle(category);
    },
    async changePage(page) {
      if (this.page === page) return;
      this.page = page;
      await this.getClients();
    },
    async changeCountOfItems(itemsPerPage) {
      if (this.itemsPerPage === itemsPerPage) return;
      this.itemsPerPage = itemsPerPage;
      await this.getClients();
    },
    async updateSort(sortBy) {
      if (this.sorting.sortBy === sortBy) return;
      this.updateRoute({ sorting: { ...this.sorting, sortBy } });
      await this.getClients();
    },
    async updateSortType(sortDesc) {
      if (this.sorting.sortDesc === sortDesc) return;
      this.updateRoute({ sorting: { ...this.sorting, sortDesc } });
      await this.getClients();
    },
    async updateMine(mine: boolean): Promise<void> {
      if (this.onlyMine === mine) return;
      this.updateRoute({ mine });
      await this.getClients();
    },
    getClients: debounce(async function ({
      limit = this.itemsPerPage,
      offset = this.skippedRows,
      sortBy = this.sorting.sortBy,
      desc = this.sorting.sortDesc,
      searchTerm = this.searchTerm,
      filters = this.filters,
      mine = this.onlyMine,
    } = {}) {
      this.loading = true;

      const { isErr, unwrap, unwrapErr } = await (this.clientService as ClientServiceContract).getAll({
        limit,
        offset,
        sortBy,
        desc,
        searchTerm,
        filters,
        mine,
      });

      this.loading = false;

      const { total, items } = unwrap();
      this.clients = items;
      this.total = total;
    },
    100),
    setFirstPage() {
      this.page = 1;
    },
    updateFilters(filters) {
      this.updateRoute({ filters });
    },
    updateSearch(searchTerm) {
      this.updateRoute({ searchTerm });
    },
    updateRoute({
      filters = this.filters,
      sorting = this.sorting,
      searchTerm = this.searchTerm,
      itemsPerPage = 15,
      mine = false,
    }) {
      const query = (this.routerEntity as RouterModelContract).getClientFilteringQuery(
        filters,
        sorting,
        searchTerm,
        itemsPerPage,
        mine,
      );
      this.$router.replace({
        path: this.$route.path,
        query,
      });
    },
  },

  destroyed() {
    this.$store.dispatch('updateSearchTerm', '');
    this.$store.dispatch('removeAllFilters');
  },
});
