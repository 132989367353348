
import Vue from 'vue';
import { format, parseISO } from 'date-fns';

import { CalendarServiceContract, TaxonomyServiceContract } from '@/injectables';
import { Services } from '@/injectables/tokens';

import { DateRangePicker } from '@/shared/ui';

import { C360Icon } from '@c360/ui';

type DateType = {
  key: string;
  from: string;
  to: string;
  title: string;
  range: [];
};

export default Vue.extend({
  name: 'ClientsFiltersMenu',

  components: { DateRangePicker, C360Icon },

  props: {
    filters: {
      type: Object,
    },
    searchTerm: {
      type: String,
      default: '',
    },
  },

  data: (): {
    nameMatchesLoading: boolean;
    showFiltersMenu: boolean;
    categoryFilter: string;
    dates: Record<string, DateType>;
    allAgencies: { PropertyId: string; name: string }[];
  } => ({
    allAgencies: [],
    nameMatchesLoading: false,
    showFiltersMenu: false,
    categoryFilter: '',
    dates: {
      modified: {
        key: 'modified',
        from: '',
        to: '',
        range: [],
        title: 'Modified date range',
      },
    },
  }),

  mounted(): void {
    this.mergeFilters(this.filters);
  },

  methods: {
    emitEvent(): void {
      this.$emit('update:filters', this.localFilters);
    },

    dateFormatter(date: Date) {
      return format(new Date(date), 'MMM dd, yyyy');
    },
    onDateRangeInput(datesStore: DateType, dates: string[]) {
      const [from = '', to = ''] = dates;
      datesStore.from = from;
      datesStore.to = to;
    },
    computedDateFormatted(date) {
      try {
        return date ? format(parseISO(date), 'MMM dd, yyyy') : '';
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('error', 'clients.computedDateFormatted', date, err);
        return '';
      }
    },
    applyFilters(): void {
      this.showFiltersMenu = false;
      this.localFilters = {
        dates: {
          modified: { ...this.dates.modified },
        },
        category: this.categoryFilter,
        searchKey: this.searchKey,
      };
      this.emitEvent();
    },
    clearDateRange(dateRange): void {
      dateRange.from = '';
      dateRange.to = '';
      dateRange.range = [];
    },
    clearFilters(): void {
      this.categoryFilter = '';
      this.range = [];
      this.clearDateRange(this.dates.modified);
      this.applyFilters();
      this.$store.dispatch('removeAllFilters');
    },
    mergeFilters(filters: Record<string, DateType>) {
      this.dates = Object.fromEntries(
        Object.entries(this.dates).map(([key, value]: [string, object]) => [key, { ...value, ...filters.dates[key] }]),
      );
      this.categoryFilter = filters.category;
    },
  },

  watch: {
    filters: {
      handler(filters: Record<string, DateType>) {
        this.mergeFilters(filters);
      },
      deep: true,
    },
  },

  computed: {
    dateRangeMin() {
      const calendarService: CalendarServiceContract = this.$container.get(Services.Calendar);
      return calendarService.dateBefore(1000, new Date()).toISOString();
    },
    searchKey: {
      get() {
        return this.searchTerm;
      },
      set(val: string) {
        this.$emit('update:search', val);
      },
    },
    // need fetch all categories for SU
    categoryList(): string[] {
      const taxonomyService: TaxonomyServiceContract = this.$container.get(Services.Taxonomy);
      if (this.$store.state.client.categories.loaded) {
        return this.$store.state.client.categories.list.map(category => ({
          value: category.id,
          text: taxonomyService.fullTitle(category.name),
        }));
      }
      return [];
    },
  },
});
