
import Vue from 'vue';

import { routes } from '@/entities/user';
import BaseAppBar from '@/entities/app/base-app-bar.vue';
import ClientSearch from '@/components/Clients/ClientsFilterMenu.vue';

import { C360Icon } from '@c360/ui';
import { mapState } from 'vuex';
import { Modules } from '../../store';

export default Vue.extend({
  name: 'ClientsAppBarWidget',

  inject: ['toggleDrawer'],

  components: {
    BaseAppBar,
    ClientSearch,
    C360Icon,
  },

  props: {
    filters: {
      type: Object,
    },
    searchTerm: {
      type: String,
    },
  },

  computed: {
    ...mapState(Modules.Agency, ['currentAgencyInfo']),
    routes() {
      return routes;
    },
    canCreateClient() {
      return this.currentAgencyInfo.canCreateClient;
    },
  },
});
